import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import Layout from '../components/layout'

import { Hero, HeroImage } from '../components/heroStyles'
import base from './../components/GlobalStylings'
import './../pages/font.module.css'

import { css, Global } from '@emotion/core'

class BlogPostTemplate extends React.Component {
  render() {
    const post = get(this.props, 'data.contentfulBlogPost')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    return (
      <>
        <Global
          styles={css`
            ${base}
          `}
        />
        <Layout location={this.props.location}>
          <div style={{ background: '#fff' }}>
            <Helmet title={`${post.title} | ${siteTitle}`} />
            <Hero className={'hero'}>
              <HeroImage alt={post.title} fluid={post.heroImage.fluid} />
            </Hero>
            <div className="wrapper">
              <h1 className="section-headline">{post.title}</h1>
              <p
                style={{
                  display: 'block',
                }}
              >
                {post.publishDate}
              </p>
              <div
                dangerouslySetInnerHTML={{
                  __html: post.body.childMarkdownRemark.html,
                }}
              />
            </div>
          </div>
        </Layout>
      </>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishDate(formatString: "MMMM Do, YYYY")
      heroImage {
        fluid(maxWidth: 1180, background: "rgb:000000") {
          ...GatsbyContentfulFluid_tracedSVG
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
